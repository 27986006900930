<template>
<div>
  <v-menu v-model="show" offset-y :close-on-content-click="false"  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field filled rounded
                    @click="queryWarehouse"
                    v-model="warehouse" label="Відділення"
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-menu-down"
                    readonly />
    </template>
    <v-sheet >
      <v-sheet class="px-5">
        <v-text-field v-model="filter" append-icon="mdi-magnify" clearable></v-text-field>
      </v-sheet>
      <v-list dense max-height="320"  style="overflow-y: auto">
        <v-subheader v-if="!warehouse" class="pl-5">Оберіть місто</v-subheader>
        <v-list-item-group>
          <v-list-item v-for="(location, index) in warehouses_filtered" :key="index"
                        @click="setWarehouse(location)">
            <v-list-item-title>{{ location.text }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-sheet>

  </v-menu>
</div>

</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "ftCitySelect",
  props : ['value','city'],
  data(){
    return {
      show : false,
      loading : false,
      warehouses : [],
      warehouse : null,
      filter : null,
    }
  },
  computed  : {
    warehouses_filtered() {
      var data = this.warehouses
      if ( this.filter ) {
        data = data.filter(el => el.text.includes(this.filter) )
      }
      return data
    },
  },
  watch : {
    city() {
      this.queryWarehouse()
      this.$emit('input', {text : '', value : ''})
    }
  },
  methods : {
    ...mapActions('location', [ 'NP_QUERY_WAREHOUSE' ]),

    queryWarehouse() {
      this.loading = true

        this.NP_QUERY_WAREHOUSE(this.city)
            .then((out)=>{
          if(out.data) {
            this.warehouses = out.data
            // if (this.warehouse) {
            //   this.warehouse = out.data[0].text
            // }
          }
          this.loading = false
        })
            .catch(()=>{ this.loading = false })

    },
    setWarehouse(location) {
      this.warehouse = location.text;
      this.$emit('input',location)
      this.show = false
    },
  },
  mounted() {
    if ( this.value ) {
      this.warehouse = this.value.text
    }
  }
}
</script>

<style scoped>

</style>