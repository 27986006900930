<template>
  <div class="d-flex justify-center ">
    <div v-for="(item,i) in items" :key="i"
         @click = "setValue(item,i)"
         :style = "activeStyle(item,i)"
         class  = "d-flex  px-1 mr-5 anim pa-2 px-4"
         :v-ripple="noRipple" >
      <div v-if="!small" class="d-flex">
        <v-icon color="#434343" class="mr-2">{{  item.icon  }}</v-icon>
        <h2  class="font-weight-light ">{{  item.text  }}</h2>
      </div>
      <div v-else class="d-flex">
        <v-icon small color="#434343" class="mr-2">{{  item.icon  }}</v-icon>
        <h4  class="font-weight-light ">{{  item.text  }}</h4>
      </div>



    </div>

  </div>
</template>

<script>
export default {
  name: "ftTabs",
  props : {
    small : Boolean,
    items : Array,
    value : String,
    color : {
      type    : String,
      default : '#912929'
    },
    noRipple : {
      default : false
    },
  },
  data() {
    return {

    }
  },
  computed : {
    border_active(){
      return `border-bottom: 2px solid ${this.color}`
    }
  },
  methods : {
    setValue(input,i) {
      if (!input.value) {  this.$emit('input',i) }
      if ( input.value) {  this.$emit('input',input.value)  }
    },
    activeStyle(input,i) {
      if (this.value === null) { return 'border-bottom: 2px solid transparent' }
      if (!input.value && i === this.value) {
        return `border-bottom: 2px solid  ${this.color}`
      }
      if ( input.value && input.value === this.value) {
        return `border-bottom: 2px solid  ${this.color}`
      }
      return 'border-bottom: 2px solid transparent'
    }
  }
}
</script>

<style scoped>
.anim {
  cursor: pointer;
  transition: all 0.3s ease;
}

</style>