<template>
  <v-sheet  class="pa-4">
    <div class="d-flex justify-space-between">
      <h1 class="font-weight-light">Моя корзина </h1>
      <v-btn v-if="Object.keys(cart).length > 0"
             color="green ligthen-2 " class="noCaps" outlined @click="$store.state.cart.showCheckout = true">
        <v-icon small class="mr-1">mdi-check</v-icon>
        Оформити</v-btn>
    </div>

    <v-simple-table
         class="mb-8 mt-4" >
      <tbody >
      <tr v-for="(product,key) in cart" :key="key" >
        <td class=" py-1"  >
          <v-avatar class="mr-2"
                    size="40"  >
            <v-img :src="product.image_small" ratio="1" />
          </v-avatar>
          {{ product.name }}
        </td>
        <td >
          <div class="d-flex align-center">
          <v-btn class="mr-3" small  icon @click="removeFromCart(product)">
            <v-icon small>mdi-minus</v-icon>
          </v-btn>
          <h3 class="mr-3">{{  cart[product.id].quantity }}</h3>
          <v-btn small  icon @click="addToCart(product)">
            <v-icon small>mdi-plus</v-icon>
          </v-btn>
        </div>
        </td>
        <td  ><h3> {{ product.price }} {{ BUSINESS_CURRENCY }}</h3>
        </td>
      </tr>
      </tbody>
    </v-simple-table>
    <div class="pb-16" v-if="Object.keys(cart).length === 0" >
      Ваша корзина порожня
    </div>
    <div v-if="Object.keys(cart).length > 0">
      <div  >
        <h6 v-if="showRentProductWarning.length > 0" class="font-weight-regular">
          Недостатньо залогових продуктів
        </h6>
        <h6 v-if="!$store.state.auth.loggedIn" class="font-weight-light">
          При першому замовленні ви платите за Тару, яка залищається вам в Залог, при наступних замовленнях, ви оформлюєте тільки воду.
          Після першого замовлення ми рахуємо Вашу тару автоматично, через Ваш оліковий запис
        </h6>
      </div>
      <div v-if="$store.state.auth.userRentedProducts">

        <div class="d-flex justify-space-between">
          <h5>У Вас в наявності :</h5>
        </div>
        <v-simple-table
            class="mb-8 mt-2" >
          <tbody >
          <tr v-for="(product,key) in $store.state.auth.userRentedProducts" :key="key" >
            <td class=" py-1"  >
              <v-avatar class="mr-2"
                        size="40"  >
                <v-img :src="product.image_small" ratio="1" />
              </v-avatar>
              {{ product.name }}
            </td>
            <td >
              <h4 class="mr-3">Вільно: {{  displayRentedProductQuantity(getRentedProductQuantity(product),product.quantity) }} із {{  product.quantity }} шт</h4>
            </td>
          </tr>
          </tbody>
        </v-simple-table>
      </div>

    </div>




    <checkoutMenu key="checkout"  v-model="$store.state.cart.showCheckout" />

<!--    <avalonBlockProducts />-->

  </v-sheet>
</template>

<script>
import {mapActions, mapState} from "vuex";
import checkoutMenu from "@/components/templates/Avalon/checkoutMenu.vue";
export default {
  name: "BusinessUserCart",
  components : {
    checkoutMenu
  },
  data() {
    return {
      showRentProductWarning : [],
    }
  },
  computed : {
    ...mapState('cart', [ 'cart']),
    ...mapState('templates', [ 'products']),
  },
  methods : {
    ...mapActions  ('cart', [ 'MAKE_ORDER', 'ADD_CART_ITEM', 'REMOVE_CART_ITEM', 'REMOVE_CART_ITEM_ALL' ]),

    addToCart(product){
      // this.$store.state.cart.cart = product
      let addProduct = this.products.find(el => el.id === product.id)
      this.ADD_CART_ITEM(addProduct)
      this.notify(`${product.name} додано до корзини`)
      // this.$forceUpdate()
    },
    removeFromCart(product) {
      let addProduct = this.products.find(el => el.id === product.id)
      this.REMOVE_CART_ITEM(addProduct)
      this.notify(`${product.name} видалено із корзини`)
    },
    removeFromCartAll(product) {
      this.REMOVE_CART_ITEM_ALL(product)
      this.notify(`${product.name} видалено із корзини`)
    },
    checkRentedFirstTime(product) {

      if ( !this.$store.state.auth.loggedIn ) {

        let quantity = 0;
        let parent = this.products.find( el=> el.id === product.id)
        if ( parent.rentedProduct ) {

          if ( this.cart[parent.rentedProduct.id] ) {
            quantity += this.cart[parent.rentedProduct.id].quantity
          }
          let warnIndex = this.showRentProductWarning.indexOf(product.id)

          if ( product.quantity > quantity ) {
            if ( warnIndex === -1) {
              this.showRentProductWarning.push(product.id)
            }
          } else {
            if ( warnIndex !== -1)  {
              this.showRentProductWarning.splice(warnIndex,1)
            }
          }
        }
      }
    },
    getRentedProductQuantity(product) {
      let quantity = product.quantity
      if ( this.cart[product.id] ) {
        quantity = quantity + this.cart[product.id].quantity
      }
      let parent = this.products.find( el=> el.id === product.id)
      if ( parent )  {
        if ( this.cart[parent.parentProduct] ) {
          quantity = quantity - this.cart[parent.parentProduct].quantity
        }
        let warnIndex = this.showRentProductWarning.indexOf(product.id)
        if ( quantity < 0 ) {
          if ( warnIndex === -1)  {
            this.showRentProductWarning.push(product.id)
          }
        } else {
          if ( warnIndex !== -1)  {
            this.showRentProductWarning.splice(warnIndex,1)
          }
        }


      }
      return quantity
    },
    displayRentedProductQuantity(quantity, total) {
      if  ( quantity < 0     ) { return 0 }
      if  ( quantity > total ) { return total }
      return quantity
    },
  }
}
</script>

<style scoped>

</style>