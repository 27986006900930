<template>
  <div>
    <v-menu content-class="dropdown"  offset-y :close-on-content-click="false" >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            filled rounded
            :value="dateInterval"
            label="Час"
            prepend-inner-icon="mdi-clock"
            readonly
            v-bind="attrs"
            hide-details
            v-on="on"
            required />
      </template>
<!--      width="160"-->
      <v-sheet  class="d-flex justify-center"  >
<!--        width="90"-->
        <!-- hours-->
        <v-sheet v-if="!timeOver" class="overflow-y-auto"  max-height="150" >
          <v-list dense>
            <v-list-item-group v-model="hours" mandatory >
              <v-list-item  v-for="(val,i) in hoursSelect" :key="i"
                            :value="val"
                            style=" min-height: auto !important; height: 25px !important"
                            class="py-0" dense   >
                <v-list-item-content>
                  <v-list-item-title class="text-center">з {{ val }}:00 по {{ val + 1 }}:00</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-sheet>
        <v-divider v-if="!timeOver" vertical></v-divider>
<!--        &lt;!&ndash; minutes&ndash;&gt;-->
<!--        <v-sheet v-if="!timeOver" class="overflow-y-auto"  max-height="150" width="70">-->
<!--          <v-list dense>-->
<!--            <v-list-item-group v-model="minutes" mandatory >-->
<!--              <v-list-item  v-for="(val,i) in minutesSelect" :key="i"-->
<!--                            :value="val"-->
<!--                            style=" min-height: auto !important; height: 22px !important"-->
<!--                            class="py-0" dense  >-->
<!--                <v-list-item-content>-->
<!--                  <v-list-item-title class="text-center">{{ val }}</v-list-item-title>-->
<!--                </v-list-item-content>-->
<!--              </v-list-item>-->
<!--            </v-list-item-group>-->
<!--          </v-list>-->
<!--        </v-sheet>-->

        <v-sheet v-if="timeOver" class="pa-4">
          <span class="caption">Не має</span>
        </v-sheet>

      </v-sheet>

    </v-menu>
  </div>
</template>

<script>
export default {
  name: "ftTimePicker",
  props : ['mStep','date','value'],
  data(){
    return {
      timeNew    : null,
      dateNow    : null,
      hours      : 0,
      minutes    : 0,
      hoursNow   : 0,
      minutesNow : 0,
      text       : '',
      currentDay : true,
      timeOver   : false,
      nextDateCount : 0
    }
  },
  computed : {
    dateInterval(){
      let dateInterval = ''
      dateInterval = "з " + this.hours + ":00 по " + (this.hours + 1) + ":00"
      return dateInterval
    },
    minutesSelect() {
      var items = []

      let minutes = 0
      if  ( this.hours === this.timeNew.getHours() ) {
        minutes = this.minutesNow;
      }
      if ( this.hours === this.workHoursStart.getHours() ) {
        minutes = this.workHoursStart.getMinutes()
      }

      for ( let i = minutes; i<60 ; i++) {
          if ( i % this.mStep === 0 ) {
            items.push(i)
          }
      }

      return items;
    },
    hoursSelect() {
      var items = []
   //   let hoursStart = this.hoursNow
      for ( let i=this.hoursNow; i<= this.workHoursEnd.getHours(); i++) {
        if ( i < 24) {
          items.push(i)
        }
      }
      return items;
    },
    time() {

        let hours = this.hours > 9 ? this.hours : `0${this.hours}`
        let minutes = this.minutes > 9 ? this.minutes : `0${this.minutes}`
      if (!this.timeOver) {
        return hours + ':' + minutes
      } else {
        return null
      }

    },
    dateObject() {
      if ( this.date ) {
        return new Date(this.date)
      }
      return null
    },
    workHoursStart() {
      if ( this.workHours ) {
        let date = new Date()
        date.setHours( this.workHours[0].slice(0,2))
        date.setMinutes( this.workHours[0].slice(3,5))
        return date
      }
      return null
    },
    workHoursEnd() {
      if ( this.workHours ) {
        let date = new Date()
        date.setHours(   this.workHours[1].slice(0,2))
        date.setMinutes( this.workHours[1].slice(3,5))
        return date
      }
      return null
    },
    workDays(){
      let items = [1,2,3,4,5,6,7];
      if ( this.$store.state.templates.selectedBusiness.workDays ) {
        if ( this.$store.state.templates.selectedBusiness.workDays.hasWorkDays ) {
          items = []
          this.$store.state.templates.selectedBusiness.workDays.items.forEach((item)=>{
            if ( item.active ) {  items.push(item.day) }
          })

        }
      }
      return items;
    },
    workHours(){
      let workHours = ['00:00','23:59'];
      if ( this.$store.state.templates.selectedBusiness.workDays ) {
        if ( this.$store.state.templates.selectedBusiness.workDays.hasWorkDays ) {
          workHours = []
          let currentDay = this.$store.state.templates.selectedBusiness.workDays.items.find(el=>el.day === this.dateObject.getDay() )
          if ( currentDay ) {
            workHours = [ currentDay.start,currentDay.end ];
          }
        }
      }
      return workHours;
    },

  },
  watch: {
    time(val) {
      this.$emit('input',val)
      this.text=val
      if ( val === null) {
        this.text='Не обрано'
      }
    },
    value() {
      this.text === this.time
    },
    mStep() {
      this.validateProps()
    },
    date() {
      this.validateProps()
    },
    workHours() {
      this.validateProps()
    }
  },
  methods : {
    nextDate() {
      if ( this.nextDateCount < 8 ) {
        let newDate = new Date(  this.dateObject.getTime()+1000*60*60*24 )
        this.$emit('updateDate',newDate.toISOString().substring(0,10))
        this.nextDateCount++;
      }
    },
    validateProps() {

      this.timeOver = false

      let mStepAllow = [1,5,10,15,20,30]
      if ( !mStepAllow.includes(this.mStep) ) {
        this.mStep = 5
      }

      this.timeNew = new Date(new Date().getTime()+1000*60*this.mStep*2)
      this.timeNew.setMinutes(this.timeNew.getMinutes()-(this.timeNew.getMinutes()%this.mStep))
      this.dateNow = new Date()
      this.value = null
      this.text = 'Не обрано'

      this.hours   = 0;
      this.miuntes = 0;

      if ( this.workDays ) {
        if ( !this.workDays.includes(this.dateObject.getDay()) ) {
          this.nextDate()
        }
      }

      if ( this.date ) {
        if ( this.dateObject.getDate() < this.timeNew.getDate() ) {
          this.nextDate()
        }
      }

      if ( this.workHours ) {

        if (this.timeNew.getTime() >=  this.workHoursStart.getTime() &&
            this.timeNew.getTime() <=  this.workHoursEnd.getTime()   &&
            this.dateObject.getDate() === this.timeNew.getDate()           ) {

          this.hours = this.timeNew.getHours();
          this.minutes = this.timeNew.getMinutes()

        } else
        if (this.timeNew.getTime() <= this.workHoursStart.getTime() ||
            this.dateObject.getDate() !== this.timeNew.getDate() ) {
          this.hours = this.workHoursStart.getHours();
          this.minutes = this.workHoursStart.getMinutes()
        } else
        {
          this.hours = null;
          this.minutes = null;
          this.timeOver = true
          this.nextDate()
        }
      }

      this.hoursNow = this.hours
      this.minutesNow = this.minutes
      this.timeOver = false

      this.text = this.time


    }
  },
  mounted() {
    this.validateProps()
  }
}
</script>

<style scoped>
.dropdown {
  /*width: 100px !important;*/
  min-width: 50px !important;
}
</style>