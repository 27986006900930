<template style="overflow-y: visible">
  <v-dialog persistent fullscreen v-model="value" color="#efefef">
    <v-sheet height="100vh">
      <!-- ORDER PROCESS-->
      <v-row key="orderProcess" v-if="!orderSuccess && Object.keys(cart).length > 0" class="pa-0 ma-0">

        <!-- LARGE PART WHITE CART-->
        <v-col cols="12" xs="12" sm="12" md="7" lg="8" xl="7" class="pa-0 ma-0">
          <v-sheet :height="displayShow(['xl','lg','md']) ? '100vh' : null" class="px-6  pb-12 overflow-y-auto">
            <div>
              <div class="d-flex justify-space-between mx-8 pt-8 ">
                <h2 class=" text-center font-weight-light">Ваше замовлення:</h2>
                <v-btn v-if="displayShow(['sm','xs'])" class="mr-n2" icon @click="value=false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>

              <div class="px-8 pt-8 pb-5 ">

                <!-- cart-->
                <v-simple-table dense class="mb-6">
                  <tbody>
                  <tr v-for="(product,key) in cart" :key="key">
                    <td :width="displayShow(['sm','xs']) ? '50px' : 'auto'"  class="text-no-wrap px-0 py-2">
                      {{ checkRentedFirstTime(product) }}
                      <div class="d-flex align-center">
                        <v-avatar class="mr-5 "
                                  :size="displayShow(['xl','lg','md']) ? 44 : 86">
                          <v-img :src="product.image_small" ratio="1"/>
                        </v-avatar>
                        <h3 v-if="displayHide(['sm','xs'])" class="font-weight-light">{{ product.name }}</h3>
                      </div>
                    </td>
                    <td v-if="displayShow(['sm','xs'])" class="py-4">
                      <h2 class="font-weight-light mb-2">{{ product.name }}</h2>
                      <div class="d-flex justify-space-between align-center">
                        <h3>Кількість: </h3>
                        <div class="d-flex  align-center ">
                          {{ checkRentedFirstTime(product) }}
                          <v-btn class="mr-3" small icon @click="removeFromCart(product)">
                            <v-icon small>mdi-minus</v-icon>
                          </v-btn>
                          <h3 class="mr-3 font-weight-light notranslate">
                            {{ cart[product.id].quantity }}
                          </h3>
                          <v-btn small icon @click="addToCart(product)">
                            <v-icon small>mdi-plus</v-icon>
                          </v-btn>
                        </div>
                      </div>
                      <div class="d-flex justify-space-between align-center">
                        <h3>Вартість: </h3>
                        <h3 class="font-weight-light mr-2"> {{ cart[product.id].quantity * product.price }} {{ BUSINESS_CURRENCY }}</h3>
                      </div>
                      <v-btn @click="removeFromCartAll(product)" class="noCaps mt-3" v-if="displayShow(['sm','xs'])" rounded outlined small>
                        Видалити
                      </v-btn>
                    </td>
                    <td v-if="displayShow(['xl','lg','md'])">
                      <div class="d-flex align-center">

                        <v-btn class="mr-3" small icon @click="removeFromCart(product)">
                          <v-icon small>mdi-minus</v-icon>
                        </v-btn>
                        <h3 class="mr-3 font-weight-light notranslate">
                          {{ cart[product.id].quantity }}
                        </h3>
                        <v-btn small icon @click="addToCart(product)">
                          <v-icon small>mdi-plus</v-icon>
                        </v-btn>
                      </div>
                    </td>
                    <td v-if="displayShow(['xl','lg','md'])"><h3 class="font-weight-light">
                      {{ cart[product.id].quantity * product.price }} {{ BUSINESS_CURRENCY }}</h3>
                    </td>
                    <td v-if="displayShow(['xl','lg','md'])" width="10px">
                      <v-btn @click="removeFromCartAll(product)" icon small>
                        <v-icon small>mdi-close</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="4">
                      <h2 class="d-flex justify-end  mt-2 mr-2">
                        <span class=" mr-2">Всього: </span> <span
                          class="notranslate">{{ getTotalPrice() - discount }}</span> {{ BUSINESS_CURRENCY }}
                      </h2>
                      <h3 v-if="discount" style="text-decoration : line-through " class="text-right"> {{  getTotalPrice() }}
                        {{ BUSINESS_CURRENCY }}</h3>
                    </td>
                  </tr>
                  </tbody>
                </v-simple-table>


                <h4> Бонусний рахунок : {{ $store.state.bonusCredits.credits - discount }} </h4>
                <ws-text-field number @change="parseDiscount($event)" v-model="credits">
                  <template #append> максимально {{ $store.state.bonusCredits.credits > (getTotalPrice() * 0.3)  ? (getTotalPrice() * 0.3) : $store.state.bonusCredits.credits  }}</template>
                </ws-text-field>


                <!-- info messages-->
                <div>


                  <v-expand-transition>
                    <v-sheet v-if=" showRentProductWarning.length > 0" rounded outlined class=" d-flex align-center px-4 py-1 mt-6">
                      <v-icon color="primary lighten-1" large class="mr-3">mdi-information</v-icon>
                      <h4 style="line-height: 1.4"  class="font-weight-regular">
                        Недостатня кількість тари, якщо у вас вже є свої Бутиля на обмін, ігноруйте це повідомлення
                      </h4>
                    </v-sheet>
                    <div class="pb-6"></div>
                  </v-expand-transition>

                  <v-sheet   rounded outlined class="d-flex align-center px-4 py-1 mt-6">
                    <v-icon color="primary lighten-1" large class="mr-3">mdi-information</v-icon>
                    <h4 style="line-height: 1.4" class="font-weight-regular">
                      Мінімальне замовлення 2 балони води
                    </h4>
                  </v-sheet>


                  <v-sheet v-if="!$store.state.auth.loggedIn" rounded outlined class="d-flex align-center px-4 py-1 mt-6">
                    <v-icon color="primary lighten-1" large class="mr-3">mdi-information</v-icon>
                    <h4 style="line-height: 1.4"  class="font-weight-regular">
                      При першому замовленні ви платите за тару, яка залишається у вас на обмін, при наступних
                      замовленнях, ви оплачуєте тільки воду, а пусту тару віддаєте водію.
                    </h4>
                  </v-sheet>
                  <v-sheet v-if="!$store.state.auth.loggedIn"  rounded outlined class="d-flex align-center px-4 py-1 mt-6">
                    <v-icon color="primary lighten-1" large class="mr-3">mdi-information</v-icon>
                    <h4 style="line-height: 1.4" class="font-weight-regular">
                      Якщо у вас вже є тара, просто видаліть Бутиль із замовлення а також вкажіть кількість балонів які
                      ви маєте
                    </h4>
                  </v-sheet>

                </div>
                <div v-if="$store.state.auth.userRentedProducts">

                  <div class="d-flex justify-space-between ">
                    <h3 class="font-weight-light">У Вас в наявності:</h3>
                  </div>
                  <v-simple-table
                      class="mb-8 mt-2">
                    <tbody>
                    <tr v-for="(product,key) in $store.state.auth.userRentedProducts" :key="key">
                      <td class=" py-1">
                        <v-avatar class="mr-2"
                                  size="40">
                          <v-img :src="product.image_small" ratio="1"/>
                        </v-avatar>
                        {{ product.name }}
                      </td>
                      <td>
                        <h4 class="mr-3">Вільно:
                          {{ displayRentedProductQuantity(getRentedProductQuantity(product), product.quantity) }}
                          із {{ product.quantity }} шт</h4>
                      </td>
                    </tr>
                    </tbody>
                  </v-simple-table>
                </div>

                <avalonBlockProductsForCart />

              </div>
            </div>
          </v-sheet>
        </v-col>
        <!-- ORDER DATA AND CONFIRMATION -->
        <v-col cols="12" xs="12" sm="12" md="5" lg="4" xl="5" class="pa-0 ma-0">
          <v-sheet :height="displayShow(['xl','lg','md']) ? '100vh' : null" color="#efefef"
                   :style="displayShow(['xl','lg','md']) ? 'position:relative' : '' "
          >

            <v-sheet
                :height="displayShow(['xl','lg','md']) ? '100vh' : null"
                :min-height="displayShow(['xl','lg','md']) ? 200 : null"
                class="overflow-y-auto"
                color="#efefef"
            >
              <div :class="displayShow(['xl','lg','md']) ? 'justify-space-between' : 'justify-center'"
                   class="d-flex  align-center mx-8 pt-5  pb-3">
                <h2  class="text-center font-weight-light mt-3">Оформлення</h2>
                <v-btn v-if="displayShow(['xl','lg','md'])" class="mr-n2" icon @click="value=false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>

              <!-- ORDER DATA-->
              <div class="compact-form">
                <!-- cart LG-->


                <!-- Name Phone -->
                <v-row>
                  <v-col cols="6">
                    <v-text-field v-model="order.name" dense filled rounded label="Ім'я"/>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field :error-messages="phoneValidationError"
                                  @blur="phoneInputFocus  = false"
                                  @focus="phoneInputFocus  = true"
                                  class="red--text"
                                  style="color:red !important"
                                  @keypress="onlyNumberInput($event)" ref="phone"
                                  placeholder="631231212"
                                  label="Телефон"
                                  v-model="phoneNumber"
                                  dense filled rounded>
                      <template slot="prepend-inner">
                        <div class="grey--text text--darken-2"
                             :class="phoneInputFocus || order.phone ? 'mt-4' : 'mt-1' "
                             style="width: 50px; transition: all 0.3s ease ">
                          {{ phoneCountryCode }}
                        </div>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- delivery type-->
                <v-sheet v-if="deliveries_items.length > 1"
                         color="grey lighten-3" class=" mb-12 mt-2 d-flex justify-center"
                         style="border-radius: 35px; padding-top: 1px;">

                  <!--                <v-btn-toggle mandatory v-model="order.delivery"  borderless>-->
                  <!--                  <v-btn  color="grey lighten-3"  class="noCaps font-weight-light" value="self">Самовивіз</v-btn>-->
                  <!--                  <v-btn @click="checkGMapText" color="grey lighten-3"  class="noCaps font-weight-light"  value="address">Доставка на адресу</v-btn>-->
                  <!--                  <v-btn color="grey lighten-3"  class="noCaps font-weight-light"  value="novaPoshta">Нова Пошта</v-btn>-->
                  <!--                </v-btn-toggle>-->

                  <ft-tabs no-ripple="true"
                           v-model="order.delivery"
                           color="#3f3f3f"
                           style="margin-top:6px"
                           @input="changeDelivery"
                           small
                           :items="deliveries_items"></ft-tabs>
                  <!-- SAVED ORDERS-->
                  <!--                <div class="mt-2" v-if="user && addresses_select.length > 0 ">-->
                  <!--                  <div class=" ">-->
                  <!--                    <v-menu offset-y>-->
                  <!--                      <template v-slot:activator="{ on, attrs }">-->
                  <!--                        <v-btn text  rounded  class="noCaps" v-bind="attrs"  v-on="on"   >-->
                  <!--                          <h3 class="font-weight-light">Збережені </h3>-->
                  <!--                          <v-icon>mdi-chevron-down</v-icon>-->
                  <!--                        </v-btn>-->
                  <!--                      </template>-->
                  <!--                      <v-list dense>-->
                  <!--                        <v-list-item-group >-->
                  <!--                          <v-list-item v-for="(item, i) in addresses_select" :key="i"  @click="loadAddress(i)">-->
                  <!--                            <v-list-item-title>{{ item.text }}</v-list-item-title>-->
                  <!--                          </v-list-item>-->
                  <!--                        </v-list-item-group>-->
                  <!--                      </v-list>-->
                  <!--                    </v-menu>-->
                  <!--                  </div>-->
                  <!--                </div>-->
                </v-sheet>

                <!-- SELF -->
                <div v-if="order.delivery === 'self' ">
                  <h4 class="mt-3 mb-6 text-center  font-weight-light">Ви можете забрати ваше замовлення за
                    адресою: </h4>
                  <v-row v-for="(location,i) in locations" :key="i">
                    <v-col cols="6">
                      <div class="d-flex align-center my-3">
                        <v-icon large color="teal" class="mr-3">mdi-map-marker</v-icon>
                        <div>
                          <h4> {{ location.address }} </h4>
                          {{ location.city.text }} <br>
                          <span v-for="(phone,i) in location.phones " :key="i"
                                class="caption">{{ phone }} {{ i !== location.phones.length - 1 ? ',' : '' }} </span>
                        </div>
                      </div>
                    </v-col>
                    <v-col>
                      <h4 class="mt-3"> Рообочій час: </h4>
                      <div><b>Пн - Пт:</b> 9:00 - 19:00</div>
                      <div><b>Cб - Нд:</b> Вихідний</div>
                    </v-col>
                  </v-row>
                </div>
                <!-- ADDRESS -->
                <div v-if="order.delivery === 'address'  ">
                  <v-row class="mt-n6">
                    <!-- Country-->
                    <v-col v-if="countriesSelect.length > 1" cols="6">
                      <v-select @change="updateContry" hide-details
                                v-model="order.country" :items="countriesSelect"
                                dense rounded filled label="Країна"/>
                    </v-col>
                    <!-- City-->
                    <v-col v-if="permittedCitiesSelect.length === 0" cols="6">
                      <div>
                        <!-- AllCities-->
                        <!-- Manual-->
                        <v-text-field v-if="!moduleIsActive('googleMaps')" hide-details
                                      dense label="Місто" rounded filled :disabled="!order.country"
                                      v-model="order.cityName"/>
                        <!-- Google Maps-->
                        <v-sheet v-else style="border-radius: 25px " color="grey lighten-3" outlined
                                 class="d-flex pa-3">
                          <v-icon class="mr-1">mdi-google-maps</v-icon>
                          <gmap-autocomplete :key="'city_' + gMapKey" @input="resetGoogle"
                                             ref="gCity"
                                             placeholder="Місто"
                                             lang="UA"
                                             style="width: 100% ;  outline: none;"
                                             :options="gMapOptionsCity"
                                             @place_changed="updateGooleLocationCity($event)"/>
                        </v-sheet>
                      </div>
                      <!-- Permitted Cities-->
                    </v-col>
                    <v-col cols="6" v-else-if="permittedCitiesSelect.length>1">
                      <div>
                        <v-select
                            rounded filled dense
                            hide-details
                            v-model="order.cityPlaceId"
                            :items="permittedCitiesSelect"
                            @input="updateCity"
                            label="Місто"/>
                      </div>
                    </v-col>
                    <!-- Address-->
                    <v-col cols="8" v-if="!moduleIsActive('googleMaps')">
                      <!-- Manual-->
                      <v-text-field v-model="address" dense filled rounded label="Адреса"/>
                    </v-col>
                    <v-col cols="8" v-else key="gmapsAddressCol">
                      <!-- Google Maps-->
                      <!--                    v-show="moduleIsActive('googleMaps')"-->
                      <v-sheet style="border-radius: 25px " color="grey lighten-2" outlined class="d-flex pa-3">
                        <v-icon class="mr-1">mdi-google-maps</v-icon>
                        <gmap-autocomplete :key="'address_' + gMapKeyAddress" @input="resetGoogleAddress"
                                           :value="address"
                                           @change="gmapAddressChange"
                                           ref="gAddress"
                                           placeholder="Назва вулиці"
                                           language="UA"
                                           style="width: 100% ;  outline: none;"
                                           :bounds="gmapBounds"
                                           :options="{ bounds : gmapBounds,
                                                  strictBounds: true,
                                                  componentRestrictions: {
                                                    country : this.order.country,
                                                  },
                                                  language : 'UA'
                                       } "
                                           @place_changed="updateGooleLocation($event)"/>
                        <v-progress-circular v-if="loading" color="grey darken-2" indeterminate></v-progress-circular>
                        <v-icon size="28" v-else :color="gMapAddressStreetCheck ? 'green lighten-2' : ''"
                                class="mr-1">mdi-check
                        </v-icon>
                      </v-sheet>
                    </v-col>
                    <!-- Street-->
                    <v-col cols="4">
                      <v-text-field @change="getGeocode" hide-details v-model="street" dense filled rounded
                                    label="Дом"/>
                    </v-col>
                    <!-- Flat-->
                    <v-col cols="6" xs="6" sm="6" md="4" lg="4" xl="4">
                      <v-text-field hide-details v-model="flat" dense filled rounded label="Квартира"/>
                    </v-col>
                    <!-- Time-->

                    <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
                      <v-select hide-details
                                disabled
                                v-model="hasDeliveryTime" :items="deliveryTimeSelect"
                                dense rounded filled label="Час доставки"/>
                    </v-col>
                    <v-col v-if="hasDeliveryTime === 'onTime'" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                      <ftDatePicker v-model="date"/>
                    </v-col>
                    <v-col v-if="hasDeliveryTime === 'onTime'" cols="12" xs="12" sm="12" md="6" lg="6" xl="4">
                      <ftTimePicker v-model="time"
                                    @updateDate="ftTimeUpdateDate"
                                    :date="date"
                                    :workDays="[1,2,3,4,5,6,7]"
                                    :workHours="['08:00','19:00']"
                                    :mStep="15"
                      />
                    </v-col>

                    <!-- Comment-->
                    <v-col cols="12">
                      <v-textarea hide-details v-model="order.comment" dense filled rounded label="Коментар"/>
                    </v-col>
                  </v-row>
                </div>
                <!-- NOVA POSHTA -->
                <div v-if="order.delivery === 'novaPoshta'">
                  <v-row>
                    <v-col cols="6">
                      <ftCitySelect v-model="npCity" @input="npUpdateCity" :densed="false"/>
                    </v-col>
                    <v-col cols="6">
                      <ftNovaPoshtaSelect v-model="npWarehouse" :city="npCity.value"/>
                    </v-col>
                  </v-row>
                </div>

              </div>

              <div v-if="this.payments.length > 1 ">

                <v-divider/>
                <h3 class="mt-5 px-8 mb-n3  font-weight-light">Оберіть спосіб оплати: </h3>

                <v-radio-group v-model="order.payment" class="compact-form">
                  <v-radio v-for="(payment,i) in payments" :key="i" :value="payment.value">
                    <template slot="label">
                <span class="font-size:0.85em; ">
                {{ payment.text }}
                </span>
                    </template>
                  </v-radio>
                </v-radio-group>

              </div>
<!--              <div class="px-8 pb-6">-->
<!--                <h5>Оплата: {{ paymentName(order.payment) }}</h5>-->
<!--              </div>-->

              <div class=" px-8 pb-8 my-8 mb-12 ">
                <v-btn class=" noCaps" rounded x-large dark color="green lighten-1" @click="nextStep" elevation="0" block>
                  <h2 class="font-weight-light">Далі</h2>
                </v-btn>
              </div>

            </v-sheet>

          </v-sheet>
        </v-col>
      </v-row>
      <!-- ORDER COMPLETION-->
      <v-sheet v-if="orderSuccess" key="orderCompletion" :height="!displayShow(['xs','sm']) ? 600 : null"
               :class="!displayShow(['xs','sm']) ? 'align-center' : null"
               class="d-flex   justify-center fill-height overflow-auto">
        <div>


          <v-sheet color="yellow lighten-3 "   rounded outlined
                   :class="displayShow(['xs','sm']) ? 'py-2' : 'px-8 py-2 mt-n13'"
                   class="d-flex align-center  ">
            <v-icon color="orange lighten-1" large class="mr-3">mdi-information</v-icon>
            <h5 style="line-height: 1.4" class="font-weight-regular">
              Через велике навантаження в сьогоднішніх умовах, ми не можемо гарантувати доставку на конкретний період часу або за розкладом. Доставка здійснюється протягом дня, по можливості в день замовлення або на наступний день.
            </h5>
          </v-sheet>

          <h3 class="text-center font-weight-light mt-6 ">
            Заявку сформовано, для підтвердження замовлення , <strong>здійсніть оплату</strong>
          </h3>

          <div v-if="showPaymentButton"
               class="d-flex justify-center mt-3">

            <liqBtn :liqData="liqButtonData">
              <v-btn rounded x-large
                     type="submit"
                     color="green lighten-2"
                     class="noCaps font-weight-regular mt-8 px-16" dark>
                <v-icon class="mr-2">mdi-credit-card</v-icon>
                <h2 class="font-weight-regular">Перейти до оплати</h2>
              </v-btn>
            </liqBtn>


          </div>


          <div class="px-16 mt-5 text-center mx-auto" style="max-width: 600px">
            <b v-if="onlineRefuse"> {{ onlineRefuseText }}</b>
            <h5 class="text-center font-weight-light mb-6">
              <i> Використовуючи номер заявки, ви можете дізнатися про статус замовлення в нашому Телеграм боті або на сайті (для цього підпишіться на бота або авторизуйтеся на сайті через номер вашого телефону або пошту)
              </i>
            </h5>
          </div>

          <v-sheet v-if="firstTimeWelcome" max-width="600" style="border-radius:10px" outlined
                   class="mx-auto mt-6 mb-6 pa-3 text-center">
            <h4 class="font-weight-light grey--text text--darken-3">Вітаємо із першою заявкою! </h4>
            <h5 style="line-height: 1.2" class="text-center font-weight-light mt-3"> Для Вас створено обліковий запис на
              нашому сайті.
              Робіть швидкі замовлення або дізнавайтесь про статус виконання, приймайте участь в наших акціях, економте
              свій час. </h5>

            <div class="text-center mt-2">
              <h5 class="text-center"><span class="font-weight-light">Логін:</span> {{ order.phone }} <span
                  class="font-weight-light"> , Пароль:</span> {{ orderNumber }} </h5>
              <h5 class="text-left"></h5>
            </div>
          </v-sheet>
          <div class=" d-flex justify-center mt-3">
            <v-chip color="grey darken-2" small dark outlined class="px-3 py-5">
              <v-icon class="mr-2">mdi-text-box-check-outline</v-icon>
              <h3>
                <span class="font-weight-light">Номер вашої заявки: </span>
                <span style="letter-spacing: 2px"> {{ orderNumber }}</span>
              </h3>
            </v-chip>
          </div>



          <!-- TELEGRAM BOT INVITATION-->
          <div v-if="!user.telegram"
               class="d-flex justify-center mt-3">
            <div class="mt-4" style="max-width: 650px">
              <h5 class="text-center font-weight-light">Під'єднайтеся до нашого телеграм бота! Ви зможете робити
                замовлення прямо з Телеграм, а також отримувати оповіщення, щодо виконання ваших замовлень, це дуже
                зручно! </h5>
              <div class="d-flex justify-center">
                <v-btn target="_blank"
                       :href="$store.state.templates.selectedBusiness.telegramAlias ?
                              $store.state.templates.selectedBusiness.telegramAlias :
                              'https://t.me/finetrading_bot'"
                       class="noCaps font-weight-regular mt-8"
                       color="blue lighten-1"
                       dark rounded>
                  <v-img width="23" class="rounded mr-3" alt="Telegram" src="@/assets/socials/telegram.png"/>
                  Telegram bot
                </v-btn>
              </div>
            </div>
          </div>
          <div class="d-flex justify-center mt-8  ">
            <v-btn class="noCaps font-weight-light ml-n4" text @click="value=false" color="grey darken-3">
              <v-icon>mdi-chevron-left</v-icon>
              Повернутися на сайт
            </v-btn>
          </div>

        </div>
      </v-sheet>
      <!-- EMPTY CART -->
      <v-sheet v-if="Object.keys(cart).length === 0 && !orderSuccess"
               class="d-flex align-center justify-center"
               :height="!displayShow(['xs','sm']) ? 600 : null">

        <div>
          <div class="d-flex align-center ">
            <v-icon class="mr-2" large color="grey darken-3">mdi-cart-outline</v-icon>
            <h2 class="text-center font-weight-light ">
              Ваша корзина порожня </h2>
          </div>
          <div class="d-flex justify-center mt-8 ml-n3 ">
            <v-btn class="noCaps font-weight-light ml-n3" text @click="value=false" color="grey darken-3">
              <v-icon>mdi-chevron-left</v-icon>
              Повернутися на сайт
            </v-btn>
          </div>
        </div>

      </v-sheet>

    </v-sheet>
  </v-dialog>
</template>

<script>
import ftCitySelect from "@/components/UI/ftCitySelect";
import ftNovaPoshtaSelect from "@/components/UI/ftNovaPoshtaSelect";
import avalonBlockProductsForCart from "@/components/templates/Avalon/UI/blocks/avalonBlockProductsForCart";
import ftTabs from "@/components/UI/ftTabs";
import {mapMutations, mapActions, mapState} from "vuex";
import {gmapApi} from "vue2-google-maps"
import ftTimePicker from "@/components/UI/ftTimePicker";
import ftDatePicker from "@/components/UI/ftDatePicker";
import liqBtn from "@/components/UI/liqBtn";

export default {
  name: "checkoutMenu",
  props: ['value'],
  components: {
    ftTabs,
    ftCitySelect,
    ftTimePicker,
    ftDatePicker,
    avalonBlockProductsForCart,
    ftNovaPoshtaSelect,
    liqBtn
  },
  data() {
    return {

      discount : 0,
      credits : 0,

      userHasRentedProduct: false,
      rentedProducts: [],

      phoneCountryCode: '+38',
      phoneNumber: '',

      phoneInputFocus: false,
      showPaymentButton: false,

      firstTimeWelcome: false,
      showRentProductWarning: [],

      onlineRefuseTextData: {
        hasArea: "Адреса вашого замовлення не попадає в нашу зону обслуговування, найближчим часом з Вами зв'яжеться наш менеджер",
      },
      onlineRefuseText: "Оплату буде прийнято після підтверждення замовлення, очійкуйте на зв'язок із менеджером",
      onlineRefuse: false,
      onlineRefuseType: 'default',

      countriesSelect: [],

      loading: false,

      liqPayData: null,
      liqButtonData: 'null',

      orderSuccess: false,
      orderNumber: '',
      readyToOrder: false,

      order: {
        name: '',
        phone: '',
        delivery: '',
        payment: '',
        products: [],
        cityName: '',
        cityPlaceId: '',
        address: '',
        npWarehouseName: '',
        npWarehouseId: '',
        time: '',
        country: '',
        countryName: '',
        gPaths: {lat: '', lng: ''}
      },

      userAddress: null,

      //technical
      deliveryTimeSelect: [
        {text: 'Як найшвидше', value: 'instant'},
        {text: 'На певний час', value: 'onTime'},
      ],
      address: null,
      flat: null,
      street: null,
      hasDeliveryTime: 'instant',
      deliveryMinutes: null,
      deliveryHours: null,
      time: '',
      date: '',
      npCity: {text: '', value: ''},
      npWarehouse: {},

      step: 0,
      tabs: [
        {text: 'Корзина', icon: 'mdi-cart-outline'},
        {text: 'Доставка', icon: 'mdi-phone-outline'},
        {text: 'Оплата', icon: 'mdi-credit-card-outline'}
      ],
      onRecieve: [
        {text: 'Готівка', icon: 'mdi-cash', value: 'onRecieve_cash'},
        {text: 'Термінал', icon: 'mdi-credit-card-outline', value: 'onRecieve_card'},
      ],
      onlinePay: [
        {text: 'Оплата на карту', icon: 'mdi-credit-card-outline', value: 'online_card_transfer'},
        {text: 'LiqPay', icon: 'mdi-credit-card-marker-outline', value: 'online_liqpay'},
        {text: 'Fondy', icon: 'mdi-credit-card-marker-outline', value: 'online_fondy'},
      ],
      deliveryTabs: [
        {text: 'Cамовивіз', icon: 'mdi-cash', value: 'self'},
        {text: 'Доставка  ', icon: 'mdi-credit-card-outline', value: 'address'},
        {text: 'Нова Пошта ', icon: 'mdi-credit-card-outline', value: 'novaPoshta'},
      ],
      onRecieveValue: '',
      onlinePayValue: '',

      //google vars
      selectedCityData: null,
      gCityName: '',
      gMapKey: 1,
      gMapKeyAddress: 1,
      gMapAddressStreetCheck: false,
      gMapAddressStreet: null,

      gmapBounds: {}
    }
  },
  computed: {
    ...mapState('cart', ['cart', 'quantity', 'deliveries', 'payments', 'showCheckout']),
    ...mapState('templates', ['products', 'businessName', 'businessAlias', 'locations', 'deliveryAllowCountries', 'deliveryCountries']),
    ...mapState('auth', ['user']),

    mapCenter() {
      let coordinates = {lat: 46.975033, lng: 31.994583}
      return coordinates
    },

    phoneValidationError() {
      if (this.order.phone) {
        if (this.order.phone.length !== 13) {
          return 'Номер телефона складається з 10 цифр'
        }
        return
      }
      return ''
    },

    google: gmapApi,
    gMapOptionsCity() {
      let country = this.order.country
      return country ? {
        types: ['(cities)'],
        componentRestrictions: {
          country: country,
        },
      } : {}
    },
    gMapOptionsAddress() {
      let data = this.selectedCityData
      return data ? {
        bounds: this.gmapBounds,
        strictBounds: true,
        componentRestrictions: {
          bounds: this.gmapBounds
        },
      } : {}
    },
    nextStepText() {
      var text = 'Далі'
      if (this.step === 2) {
        text = 'Оформити'
      }


      if (this.order.products &&
          this.order.phone &&
          this.order.delivery.type &&
          this.order.payment) {
        if (this.order.delivery.type === 'self') {
          text = 'Оформити'
        }
        if (this.order.delivery.type === 'address') {
          if (this.order.delivery.data.address) {
            text = 'Оформити'
          }
        }
      }


      return text

    },
    onRecieveFiltered() {
      let output = []
      this.onRecieve.forEach((payment) => {
        if (this.payments.findIndex(el => el.value === payment.value) !== -1) {
          output.push(payment)
        }
      })
      return output
    },
    onlinePayFiltered() {
      let output = []
      this.onlinePay.forEach((payment) => {
        if (this.payments.findIndex(el => el.value === payment.value) !== -1) {
          output.push(payment)
        }
      })
      return output
    },
    addresses() {
      if (this.user) {

        var locations = this.user.locations.length > 0 ? this.user.locations.filter(el => el.type === 'address') : []


        // if ( !this.deliveryPermissions.includes('CITIES') && this.permittedCities.length >0 ) {
        //   locations = locations.filter(el =>
        //       this.permittedCities.filter( elem =>
        //           elem.city.text === el.data.city.text ).length > 0 )
        // }
        //
        // if ( !this.deliveryPermissions.includes('COUNTRIES')  && this.permittedCountries.length > 0  ) {
        //   locations = locations.filter(el =>
        //       this.permittedCountries.filter( elem => elem === el.data.country ).length > 0 )
        // }

        return locations

      }


      return []

    },
    addresses_select() {
      var items = [];
      this.addresses.forEach((address, i) => {
        items.push({
          text: address.data.address + ', ' + address.data.city.text,
          value: i
        })
      })
      return items
    },
    deliveries_items() {
      let output = []
      this.deliveryTabs.forEach((delivery) => {
        if (this.deliveries.findIndex(el => el.value === delivery.value) !== -1) {
          output.push(delivery)
        }
      })
      return output
    },
    permittedCitiesSelect() {
      let cities = [];
      if (this.order.country) {

        let index = this.deliveryCountries.findIndex(el => el.cca2 === this.order.country)
        if (index !== -1) {
          if (!this.deliveryCountries[index].allCities) {
            this.deliveryCountries[index].permittedCities.forEach((city) => {
              cities.push({
                text: city.name,
                value: city.placeId
              })
            })
          }
        }

      }

      return cities;
    },

    //technical
    deliveryAddress() {
     if (this.flat) {
       let string =  this.address + " " + this.street + ", Кв. " + this.flat
       return string
     } else {
       return this.address
     }

    },
    deliveryTime() {
      return this.date + ' ' + this.time
    },
  },
  watch: {

    address(value) {
      if (value && this.street) {
        this.getGeocode()
      }
    },
    phoneNumber(value) {
      this.order.phone = this.phoneCountryCode + value
    },
    selectedCityData() {
      this.getGmapBounds()
    },
    showCheckout(val) {
      if (val === true) {
        this.initCheckout()
      }
    },

    value(value) {
      this.$emit('input', this.value)
      if (value === false && this.orderSuccess) {
        this.orderSuccess = false;
        this.firstTimeWelcome = false;
        this.onlineRefuse = false;
      }
    },
    deliveryAddress(val) {
      this.order.address = val
    },
    user() {
      this.setUser()
    },
    'order.country'(val) {
      let country = this.countriesSelect.find(el => el.value === val)
      if (country) {
        this.order.countryName = country.text
      }
    },
    deliveryTime(val) {
      this.order.time = val
    },
    hasDeliveryTime(val) {
      if (val === 'instant') {
        this.order.time = ''
        this.time = ''
        this.date = ''
      }
    },
    npWarehouse(val) {
      this.order.npWarehouseName = val.text
      this.order.npWarehouseId = val.value
    },
    npCity() {
    }
  },
  methods: {
    ...mapActions('cart', ['MAKE_ORDER', 'ADD_CART_ITEM', 'REMOVE_CART_ITEM',]),
    ...mapActions('liqpay', ['GET_BUSINESS_BUTTON']),
    ...mapMutations('cart', ['REMOVE_CART_ITEM_ALL']),
    ...mapActions('auth', ['GET_USER']),
    ...mapActions('location', ['GMAP_GEOCODE_ADDRESS']),

    parseDiscount(credits) {

      this.discount = credits

      if ( credits > (this.getTotalPrice() * 0.3) ) {
        this.credits = this.getTotalPrice() * 0.3
        this.discount = this.getTotalPrice() * 0.3

        if ( parseInt(this.credits) > parseInt(this.$store.state.bonusCredits.credits) ) {
          this.credits  = parseInt(this.$store.state.bonusCredits.credits)
          this.discount = parseInt(this.$store.state.bonusCredits.credits)
          return
        }

      return

      }

      if ( parseInt(credits) > parseInt(this.$store.state.bonusCredits.credits) ) {
        this.credits  = parseInt(this.$store.state.bonusCredits.credits)
        this.discount = parseInt(this.$store.state.bonusCredits.credits)
        return
      }

      this.discount = credits

    },

    onlyNumberInput(input) {

      input = (input) ? input : window.event;
      var charCode = (input.which) ? input.which : input.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        input.preventDefault();
      } else {
        return true;
      }


    },


    goToPaymentCleanUp() {

    },

    initCheckout() {

      // if ( this.order.gPaths ) {
      //   this.$refs.gAddress.$el.value = this.order.address
      // }
      this.checkFastDeliveryAddress()

    },
    checkGMapText() {
      if (this.order.gPaths) {
        this.$refs.gAddress.$el.value = this.order.address
      }
    },
    validateOrder() {

      let needToFill = ''

      if (this.phoneValidationError) {
        this.notify('Не правильний формат номера телефона. Введіть 10 цифр')
        return false
      }
      if (!this.order.phone) {
        needToFill += `${needToFill ? ', ' : ''}Телефон`;
      }
      if (!this.order.name) {
        needToFill += `${needToFill ? ', ' : ''}Ім'я`;
      }
      if (!this.order.delivery) {
        needToFill += `${needToFill ? ', ' : ''}Тип доставки`;
      }
      if (!this.order.payment) {
        needToFill += `${needToFill ? ', ' : ''}Тип оплати`;
      }

      if (this.order.delivery === 'address') {
        if (this.countriesSelect.length > 1) {
          if (!this.order.country) {
            needToFill += `${needToFill ? ', ' : ''}Країна`;
          }
        }
        if (!this.order.cityName) {
          needToFill += `${needToFill ? ', ' : ''}Місто`;
        }
        if (!this.address) {
          needToFill += `${needToFill ? ', ' : ''}Адреса`;
        }

        if (this.moduleIsActive('googleMaps')) {
          if (!this.gMapAddressStreetCheck) {
            this.notify('Номер вулиці не вказано, введіть номер та оберіть із списку адресу')
            return false
          }
          if (!this.order.gPaths) {
            this.notify('Google Maps адресу не знайдено, введыть адресу знову')
            return false
          }
        }


      }
      if (this.order.delivery === 'novaPoshta') {
        if (!this.order.npWarehouseId) {
          needToFill += `${needToFill ? ', ' : ''}Cклад Нової Пошти`;
        }
      }

      if (Object.keys(this.cart).length === 0) {
        this.notify('Ваша корзина порожня')
        return false
      }

      if (needToFill) {
        this.notify('Заповніть необхідні поля: ' + needToFill)
        return false
      }


      return true

    },

    nextStep() {
      this.sendOrder()
    },
    sendOrder() {
      this.showPaymentButton = false
      if (this.validateOrder()) {
        this.step = 4
        this.order.products = []
        Object.keys(this.cart).forEach((key) => {
          this.order.products.push({id: this.cart[key].id, quantity: this.cart[key].quantity})
        })
        this.loading = true
        if ( this.flat ) {
          this.order.address = this.address + ' ' + this.street + ', кв. ' + this.flat
        } else {
          this.order.address = this.address + ' ' + this.street
        }
        this.order.origin = this.DEVICE
        this.MAKE_ORDER({
          alias: this.businessAlias,
          data: this.order
        }).then((out) => {
          if (out.result) {

            if (out.data.rentedProducts) {
              this.$store.state.auth.userRentedProducts = out.data.rentedProducts
              this.$store.state.auth.user.rent_product = out.data.rentedProducts
            }
            if (out.data.user) {
              this.firstTimeWelcome = true
              this.$store.state.auth.accessToken = out.data.user.access
              localStorage.setItem('access_token', out.data.user.access)
              localStorage.setItem('refresh_token', out.data.user.refresh)

              this.GET_USER({
                token: out.data.user,
                router: {
                  router: this.$router,
                  path: this.$route.path
                },
                redirect: false,
              })
            }

            if (this.order.payment === 'online_liqpay') {
              this.onlineRefuse = false;
              if (out.data.liqPayPermission) {
                this.GET_BUSINESS_BUTTON({
                  business: this.businessAlias,
                  data: {
                    order_number: out.data.order_number,
                    total: this.getTotalPrice(),
                    return_url: location.href
                  },
                }).then((button) => {
                  this.showPaymentButton = true
                  this.liqButtonData = button.data
                  // this.LIQPAY_checkout(button.data)
                }).catch((error) => {
                  console.log(error)
                })
              } else {
                this.onlineRefuse = true
                if (this.onlineRefuseTextData[out.data.liqPayPermissionType]) {
                  this.onlineRefuseText = this.onlineRefuseTextData[out.data.liqPayPermissionType]
                }
              }
            }

            this.notify('Замовлення створено')
            this.orderSuccess = true
            this.clearOrder();
            this.orderNumber = out.data.order_number
            this.loading = false


          } else {
            this.step = 2;
          }

        }).catch(() => {
          this.loading = false
        })
      }
    },
    clearOrder() {
      this.step = 0
      this.$store.state.cart.cart = {}
      this.$store.state.cart.quantity = 0
      this.order.products = []
    },

    addToCart(product) {
      // this.$store.state.cart.cart = product
      let addProduct = this.products.find(el => el.id === product.id)
      this.ADD_CART_ITEM(addProduct)
      this.notify(`${product.name} додано до корзини`)
      // this.$forceUpdate()
    },
    removeFromCart(product) {
      let addProduct = this.products.find(el => el.id === product.id)
      this.REMOVE_CART_ITEM(addProduct)
      this.notify(`${product.name} видалено із корзини`)
    },
    removeFromCartAll(product) {
      this.REMOVE_CART_ITEM_ALL(product)
      this.notify(`${product.name} видалено із корзини`)
    },
    setPayment(type) {
      switch (type) {
        case 'onRecieve' :
          this.onlinePayValue = null
          this.order.payment = this.onRecieveValue
          break;
        case 'onlinePay' :
          //  this.notify('im online')
          this.onRecieveValue = null
          this.order.payment = this.onlinePayValue
          break;
      }
    },
    setUser() {

      if (this.user) {
        this.order.name = this.user.firstname
        this.order.phone = this.user.phone
        if (this.user.phone) {
          this.phoneCountryCode = this.order.phone.slice(0, 3)
          this.phoneNumber = this.order.phone.slice(3, 13)
        }
        this.order.user = this.user.id

        // if ( this.user.last_order ) {
        //
        //   if ( this.user.last_order.delivery.type === 'self' ) {
        //     this.order.delivery.type = 'self'
        //   } else {
        //     this.order.delivery = this.user.last_order.delivery
        //   }
        //
        //   this.order.payment = this.user.last_order.payment
        // }

      } else {

        this.order.name = ''
        this.order.phone = ''
        delete this.order.user

      }

    },
    loadAddress(index = null) {

      this.order.delivery = this.addresses[index]

    },

    //google
    resetGoogleAddress() {
      this.order.gPaths = null
      this.gMapAddressStreetCheck = false
      this.gMapAddressStreet = null

    },
    updateGooleLocation($event) {

      let streetName = $event.address_components.find(el => el.types.includes('route'))
      //   let streetNumber = $event.address_components.find( el=> el.types.includes('street_number') )
      this.address = streetName ? streetName.long_name : ''
      //  this.street = streetNumber ?  streetNumber.long_name : ''
      this.$refs.gAddress.$el.value = this.address

    },
    updateGooleLocationCity($event) {
      let postal_code = $event.address_components.find(el => el.types.includes('postal_code'))
      let city = {
        postal_code: postal_code.long_name,
        name: $event.name,
        placeId: $event.place_id,
        bounds: $event.geometry.viewport.toJSON(),
        center: $event.geometry.location.toJSON(),
      }
      this.selectedCityData = city
      this.$refs.gCity.$el.value = city.name
      this.gMapKeyAddress++;

    },

    updateContry() {
      this.order.cityName = ''
      this.order.cityPlaceId = ''
      this.gMapKey++;
      this.selectedCityData = null
      this.gCityName = ''
    },
    updateCity(val) {

      let index = this.deliveryCountries.findIndex(el => el.cca2 === this.order.country)
      if (index !== -1) {
        let cityData = this.deliveryCountries[index].permittedCities.find(el => el.placeId === val)
        if (cityData) {
          this.selectedCityData = cityData
          this.order.cityName = cityData.name
          this.order.cityPlaceId = cityData.placeId

          this.order.address = ''
          this.order.gPaths = {}
          this.gMapAddressStreetCheck = false
          this.street = ''
        }
      }
    },

    // technical
    deliveryName(id) {
      let delivery = 'не обрано';
      let item = this.deliveries.find(el => el.value === id)
      if (item) {
        delivery = item.text
      }
      return delivery
    },
    paymentName(id) {
      let payment = 'не обрано';
      let item = this.payments.find(el => el.value === id)
      if (item) {
        payment = item.text
      }
      return payment
    },
    getTotalPrice() {
      let total = 0;
      Object.keys(this.cart).forEach((key) => {
        total += parseFloat(this.cart[key].quantity) * parseFloat(this.cart[key].price)
      })
      return total
    },
    changeDelivery(val) {
      this.order.delivery = val
    },
    ftTimeUpdateDate(val) {
      this.date = val
    },
    npUpdateCity(val) {
      this.order.cityName = val.text
      this.order.cityPlaceId = val.value
    },
    checkRentedFirstTime(product) {

      if (!this.$store.state.auth.loggedIn) {

        let quantity = 0;
        let parent = this.products.find(el => el.id === product.id)
        if (parent.rentedProduct) {

          if (this.cart[parent.rentedProduct.id]) {
            quantity += this.cart[parent.rentedProduct.id].quantity
          }
          let warnIndex = this.showRentProductWarning.indexOf(product.id)

          if (product.quantity > quantity) {
            if (warnIndex === -1) {
              this.showRentProductWarning.push(product.id)
            }
          } else {
            if (warnIndex !== -1) {
              this.showRentProductWarning.splice(warnIndex, 1)
            }
          }
        }
      }
    },
    getRentedProductQuantity(product) {
      let quantity = product.quantity
      if (this.cart[product.id]) {
        quantity = quantity + this.cart[product.id].quantity
      }
      let parent = this.products.find(el => el.id === product.id)
      if (parent) {
        if (this.cart[parent.parentProduct]) {
          quantity = quantity - this.cart[parent.parentProduct].quantity
        }
        let warnIndex = this.showRentProductWarning.indexOf(product.id)
        if (quantity < 0) {
          if (warnIndex === -1) {
            this.showRentProductWarning.push(product.id)
          }
        } else {
          if (warnIndex !== -1) {
            this.showRentProductWarning.splice(warnIndex, 1)
          }
        }


      }
      return quantity
    },

    displayRentedProductQuantity(quantity, total) {
      if (quantity < 0) {
        return 0
      }
      if (quantity > total) {
        return total
      }
      return quantity
    },

    getGmapBounds() {

      let data = this.selectedCityData

      this.$gmapApiPromiseLazy().then(() => {
        this.gmapBounds = data ? new this.google.maps.LatLngBounds(
            new this.google.maps.LatLng(data.bounds.south, data.bounds.west),
            new this.google.maps.LatLng(data.bounds.north, data.bounds.east),
        ) : {}
      })
      this.gMapKeyAddress++
      console.log("** ======= GMAP BOUNDS ======== **")
    },
    checkFastDeliveryAddress() {

      if (this.$store.state.cart.quickDeliveryField.fromGoogle) {

        if (this.$store.state.cart.quickDeliveryField.street_name) {
          this.address = this.$store.state.cart.quickDeliveryField.street_name
        } else {
          this.address = ''
        }
        if (this.$store.state.cart.quickDeliveryField.street_number) {
          this.street = this.$store.state.cart.quickDeliveryField.street_number
        } else {
          this.street = ''
        }
      } else {
        this.address = this.$store.state.cart.quickDeliveryField.string
      }
      this.getGeocode()
      this.$store.state.cart.quickDeliveryField.complete = true

    },
    gmapAddressChange(value) {
      this.address = value.target.value
      this.getGeocode()
    },
    getGeocode() {
      this.gMapAddressStreetCheck = false
      if (this.street && this.address) {
        let querry = this.address + ', ' + this.street
        // if ( this.order.country ) { querry = this.order.cityName + ', ' + querry }
        // if ( this.order.countryName ) { querry = this.order.countryName + ', ' + querry }
        let bounds = this.gmapBounds.toString()
        bounds = bounds
            .replace('((', '')
            .replace('))', '')
            .replace('), (', '|')

        let data = {
          address: querry,
          bounds: bounds,
          country: this.order.country,
          postal_code: this.selectedCityData.postal_code
        }
        this.loading = true
        this.GMAP_GEOCODE_ADDRESS(data).then((out) => {

          if (out.result) {
            if (out.data.status !== 'OK') {
              this.gMapAddressStreetCheck = false
              this.loading = false
              // this.notify('Адресу не знайдено,  оберіть адресу із випадаючого списку')
              return
            }
            if (out.data.results.length === 1) {
              let street_check = out.data.results[0].address_components.find(el => el.types.includes('street_number'))
              if (!street_check) {
                this.loading = false
                return this.notify('Адресу не знайдено,  оберіть адресу із випадаючого списку')
              }
              this.order.gPaths = out.data.results[0].geometry.location
              this.order.address = querry
              this.gMapAddressStreetCheck = true
            }
          }
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      }
    }
  },

  mounted() {
    this.rentedProducts = JSON.parse(JSON.stringify(this.$store.state.templates.rentedProducts))
    this.rentedProducts.forEach((product) => {
      product.quantity = 0
    })
    this.firstTimeWelcome = false
    this.onlineRefuse = false

    // Set Delivery type if business has only one type

    if (this.deliveries_items.length === 1) {
      this.order.delivery = this.deliveries_items[0].value
    }
    if (this.payments.length === 1) {
      this.order.payment = this.payments[0].value
    }

    if (this.deliveryAllowCountries.includes('COUNTRIES')) {
      fetch('https://restcountries.com/v3.1/all').then((resp) => resp.json()).then((out) => {
        out.forEach((country) => {
          this.countriesSelect.push({text: country.name.common, value: country.cca2})
        })
      })
    } else {

      this.deliveryCountries.forEach((country) => {
        this.countriesSelect.push({text: country.name, value: country.cca2})
      })
      if (this.deliveryCountries.length === 1) {
        this.order.country = this.deliveryCountries[0].cca2
        if (this.deliveryCountries[0].permittedCities.length > 0) {
          this.order.cityName = this.deliveryCountries[0].permittedCities[0].name
          this.order.cityPlaceId = this.deliveryCountries[0].permittedCities[0].placeId
          this.selectedCityData = this.deliveryCountries[0].permittedCities[0];
          this.gMapKeyAddress++;
        }
      }
    }

    this.countriesSelect.sort((a, b) => a.text.localeCompare(b.text))
    this.setUser()

    this.getGmapBounds()


    // if ( this.locations.length > 0 ) {
    //   this.permittedCountries.push(this.locations[0].country)
    //   this.permittedCities.push( {city : this.locations[0].city, country : this.locations[0].country})
    // }
    //
    // this.locations.forEach( location => {
    //       if ( this.permittedCities.filter( el => el.city.text === location.city.text).length === 0 ) {
    //         this.permittedCities.push({city : location.city, country : location.country})
    //       }
    //   if ( this.permittedCountries.filter( el => el === location.country).length === 0 ) {
    //     this.permittedCountries.push(location.country)
    //   }
    // })
    // this.singleCity = this.permittedCities.length === 1 && !this.deliveryPermissions.includes('CITIES')
    // if ( !this.deliveryPermissions.includes('CITIES') && this.permittedCountries.length === 1  ) {
    //   this.order.delivery.data.country = this.businessCountry
    // }


  },

}
</script>

<style scoped>
.smallText {
  font-size: 0.875rem
}
</style>