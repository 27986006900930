<template>
  <div>
    <form ref="ref_id"  method="POST" :target="target" :id="ref_id"
          :action="liqData.action"
          :accept-charset="this.liqData.acceptCharset">
      <input type="hidden" name="data"      :value="this.liqData.data" />
      <input type="hidden" name="signature" :value="this.liqData.signature" />
      <slot></slot>
    </form>


  </div>
</template>

<script>
export default {
  name: "liqBtn",
  props : ['liqData','target','ref_id']
}
</script>

<style scoped>

</style>