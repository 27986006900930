<template>
  <v-menu
      ref="menu"
      v-model="showMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">

      <v-text-field
          filled rounded
          hide-details
          v-model="date"
          label="Дата"
          prepend-inner-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
          required />

    </template>
    <v-date-picker
        header-color="blue lighten-2"
        :allowed-dates="testing"
        no-title
        ref="picker"
        locale="uk-UA"
        v-model="date"
        :min="new Date().toISOString().substr(0, 10)"
        @change="$refs.menu.save(date)"
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "ftDatePicker",
  props : ['value'],
  data() {
    return {
      date : new Date().toISOString().substr(0, 10),
      showMenu : false
    }
  },
  computed : {
    workDays(){
      let items = [1,2,3,4,5,6,7];
      if ( this.$store.state.templates.selectedBusiness.workDays ) {
        if ( this.$store.state.templates.selectedBusiness.workDays.hasWorkDays ) {
          items = []
          this.$store.state.templates.selectedBusiness.workDays.items.forEach((item)=>{
            if ( item.active ) {  items.push(item.day === 7 ? 0 : item.day ) }
          })

        }
      }
      return items;
    }
  },
  watch : {
    date(val) {
      this.$emit('input',val)
    },
    value(val) {
      this.date = val
    }
  },
  methods : {
    validate() {
      if ( !this.value) {
        this.$emit('input',this.date)
      }
    },
    testing( val) {
      let date = new Date(val);
      if (this.workDays) {
        return this.workDays.includes(date.getDay())
      } else {
        return true
      }

    }
  },
  mounted() {
    this.validate()
  }
}
</script>

<style scoped>

</style>